<template>
  <div>
    <!-- class="tw-hero tw-min-h-screen" -->
    <div
      class="tw-hero tw-h-96 home-bg "
    >
      <div class="tw-hero-overlay tw-h-96 tw-bg-opacity-80"></div>
      <div class="tw-text-center tw-hero-content tw-text-neutral-content">
        <div class="tw-max-w-2xl">
          <h1 class="tw-mb-5 tw-text-3xl tw-font-bold">
            Give Phox to a friend
          </h1>
          <p class="tw-mb-5 tw-italic">
            We so appreciate you taking the time to share Phox.
          </p>
          <p class="tw-mb-5">
            As a thank you for your referral, you will receive a
            <span class="tw-mb-5 tw-font-bold tw-underline">
              Free Month Credit on your account!*
            </span>
          </p>

          <button
            class="tw-btn tw-btn-primary"
            @click="scrollToForm"
          >
            Refer a friend now!
          </button>
        </div>
      </div>
    </div>
    <v-row>
      <v-col
        cols="12"
        md="12"
        sm="12"
      >
        <iframe
          id="referAFriendForm"
          v-resize.quiet="{log:true}"
          width="100%"
          :src="URLmade"
          frameborder="0"
          :height="iframeHeight"
          scrolling="no"
        ></iframe>
      </v-col>
    </v-row>
  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import {
  mdiFilePdf,
  mdiArrowRight,
  mdiMessageTextOutline,
  mdiPhone,
  mdiVideoPlus,
  mdiAccountCircle,
  mdiBasketPlus,
  mdiPost,
} from '@mdi/js'
import iFrameResize from 'iframe-resizer/js/iframeResizer'
import Vue from 'vue'
import { mapState } from 'vuex'

Vue.directive('resize', {
  bind(el, { value = {} }) {
    el.addEventListener('load', () => iFrameResize(value, el))
  },
})
export default {
  name: 'ReferAFriend',
  directives: {
    'iframe-resize': {
      bind(el, binding) {
        iFrameResize(binding.value, el)
      },
    },
  },
  setup() {
    return {
      icons: {
        mdiFilePdf,
        mdiArrowRight,
        mdiMessageTextOutline,
        mdiPhone,
        mdiVideoPlus,
        mdiAccountCircle,
        mdiBasketPlus,
        mdiPost,
      },
    }
  },
  data() {
    return {
      URLmade: '',
      prePopulate: {},
      iframeHeight: '',
    }
  },
  computed: {
    ...mapState(['User']),
  },
  mounted() {
    window.addEventListener('message', this.receiveMessage)
    this.getURL()
  },
  beforeDestroy() {
    window.removeEventListener('message', this.receiveMessage)
  },
  methods: {
    scrollToForm() {
      document.getElementById('referAFriendForm').scrollIntoView({
        behavior: 'smooth',
      })
    },
    receiveMessage(event) {
      this.eventData = event.data
      if (typeof this.eventData === 'object') {
      } else {
        const myInt = parseInt(this.eventData.replace(/^[^0-9]+/, ''), 10)
        this.iframeHeight = myInt
      }
    },
    async getURL() {
      // this.URLmade = `https://form.jotform.com/212736511415045?name=${this.$store.state.User.user.}&nojump`
      this.URLmade = `https://form.jotform.com/220104935292248?referrerFirst=${this.$store.state.User.user.firstName}&referrerLast=${this.$store.state.User.user.lastName}&email=${this.$store.state.User.user.email}&pharmName=${this.$store.state.User.pharmacy.name}&phoneNumber=${this.$store.state.User.pharmacy.phone}&nojump`
    },
  },
}
</script>

<style lang="scss" scoped>
.home-bg {
  background-image: url('~@/assets/images/banners/referafriend.png') !important;
  background-position: 40% 40%;
  background-size: cover;
}
</style>
